import React from "react";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { ServicePicker } from "../../Shared/Services/SerivcePicker/SerivcePicker";

export interface HomeServicesProps {
  className?: string;
}

export const HomeServices = (props: HomeServicesProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className} bg-secondary py-16`}>
      <SectionHeader
        className="text-center"
        title="AKO VÁM MÔŽEM POMÔCŤ?"
        subtitle="Realitné služby"
        variant="light"
      />
      <div className="services mt-16 text-white">
        <ServicePicker />
      </div>
    </section>
  );
};
