import {
    useToast
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { validationMessages } from "../../../../lib/consts";
import { useCreateValuationSubmissionMutation } from "../../../../lib/features/form/valuation/hooks/useCreateValuationSubmissionMutation";
import { CreateValuationFormSubmissionInput } from "../../../../lib/features/form/valuation/model/valuation-form.model";
import { Button } from "../../../Shared/Button/Button";
import { AgreementCheckbox } from "../../../Shared/Form/Inputs/AgreementCheckbox";
import { TextInput } from "../../../Shared/Form/Inputs/TextInput";

export interface AppointmentFormProps {
  className?: string;
}

export const AppointmentForm = (props: AppointmentFormProps) => {
  const { className = "" } = props;
  const toast = useToast();

  const methods = useForm({
    mode: "onSubmit",
  });

  const { mutate, isLoading } = useCreateValuationSubmissionMutation({
    onSuccess: () => {
      methods.reset({ agreement: false });
      toast({
        title: "Form odoslaný",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Form sa nepodarilo odoslať",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const {
    formState: { errors },
  } = methods;

  const onSubmit = async (data: CreateValuationFormSubmissionInput) => {
    mutate(data);
  };

  console.log(errors);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={`${className}`}
      >
        <div className="fields grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10">
          <TextInput
            name="nameAndSurname"
            label="Meno a priezvisko"
            placeholder="meno a priezvisko"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            name="email"
            label="Email"
            placeholder="e-mail"
            options={{
              pattern: {
                value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                message: validationMessages.email,
              },
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            name="phone"
            label="Telefonický kontakt"
            placeholder="telefonický kontakt"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            name="typeAndLocation"
            label="Typ nehnuteľnosti a lokalita"
            placeholder="napr. byt, Bratislava ružinov"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
        </div>
        <div className="actions mt-16 flex justify-center flex-col-reverse xl:flex-row xl:space-x-12">
          <Button
            isLoading={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            className="mt-8 xl:mt-0"
          >
            OCENIŤ NEHNUTEĽNOSŤ
          </Button>
          <div className="div">
            <AgreementCheckbox />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
