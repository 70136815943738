import { Box } from "@chakra-ui/react";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { routes } from "../../../lib/routes";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import { useLeftSmileImage } from "../../Shared/hooks/useLeftSmileImage";
import { List } from "../../Shared/List/List";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";

export interface HowIWorkProps {
  className?: string;
}

export const HowIWork = (props: HowIWorkProps) => {
  const { className = "" } = props;
  const image = useLeftSmileImage();

  return (
    <section
      style={{
        maxWidth: "100vw",
      }}
      className={`${className} overflow-hidden`}
    >
      <Container className="flex lg:space-x-16 flex-wrap lg:flex-nowrap justify-center lg:justify-start">
        <Box
          width={{
            base: "full",
            lg: "5/12",
          }}
          maxWidth="500px"
          data-sal="slide-right"
          data-sal-delay={200}
          data-sal-easing="ease"
          data-sal-duration="800"
          className="image w-6/12 lg:w-5/12 order-2 lg:order-1 mt-12 lg:mt-0"
        >
          <div className="image-container w-60 sm:w-72 lg:w-80 ml-auto z-0 relative">
            <GatsbyImage fluid={image} />
          </div>
          <div className="panel h-16 bg-secondary z-20 relative -mt-14">
            <div className="absolute -right-3 lg:-right-5 top-0 bottom-0 flex items-center">
              <Link to={routes.howIWork.to}>
                <Button>CHCETE SA DOZVEDIEŤ VIAC?</Button>
              </Link>
            </div>
          </div>
        </Box>
        <div
          data-sal="slide-left"
          data-sal-delay={300}
          data-sal-easing="ease"
          data-sal-duration="800"
          className="info order-1 lg:order-2"
        >
          <SectionHeader
            title="VYŽADUJETE INDIVIDUÁLNY PRÍSTUP?"
            subtitle="Ako pracujem?"
            description="Poskytujem klientom vysoký štandard služieb"
          />
          <div className="items mt-8">
            <List
              items={[
                "Dôkladná cenová analýza nehnuteľnosti na základe osobného stretnutia s Vami. Odborne posúdim stav a trhovú cenu nehnuteľnosti.",
                "Profesionálne odprezentujem Vašu nehnuteľnosť čo zaručí predaj za najvyššiu možnú cenu. Možnosť využitia licitačného systému.",
                "Chránim Vás v priebehu celého procesu predaja, aby Vám peniaze bezpečne prišli na účet. Kvalitné právne služby sú samozrejmosťou.",
              ]}
            />
          </div>
          <p className="mt-5">
            Potrebujete vedieť viac informácií? Neváhajte ma kontaktovať. Som tu
            pre Vás.
          </p>
        </div>
      </Container>
    </section>
  );
};
