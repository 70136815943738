import { useMutation, UseMutationOptions } from "react-query";
import { createValuationFormSubmission } from "../api/valuation-form.api";
import {
    CreateValuationFormSubmissionInput,
    CreateValuationFormSubmissionReturn
} from "../model/valuation-form.model";

export const useCreateValuationSubmissionMutation = (
  options: UseMutationOptions<
    CreateValuationFormSubmissionReturn,
    null,
    CreateValuationFormSubmissionInput
  > = {}
) => {
  return useMutation<
    CreateValuationFormSubmissionReturn,
    null,
    CreateValuationFormSubmissionInput
  >(createValuationFormSubmission, {
    ...options,
  });
};
