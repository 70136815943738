import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { AppointmentForm } from "./components/AppointmentForm";

export interface AppointmentFormSectionProps {
  className?: string;
}

export const AppointmentFormSection = (props: AppointmentFormSectionProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className} py-16 bg-section`}>
      <Container>
        <SectionHeader
          className="text-center"
          subtitle="Bezplatné ocenenie nehnuteľnosti"
          title="REZERVUJTE SI TERMÍN STRETNUTIA"
          description="Potrebujete zistiť hodnotu vašej nehnuteľnosti a neviete ako?"
        />
        <div className="form mt-16">
          <AppointmentForm />
        </div>
      </Container>
    </section>
  );
};
