import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes";
import { LocationSectionHeader } from "../../Location/shared/LocationSectionHeader/LocationSectionHeader";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import { LocationCards } from "../../Shared/LocationCards/LocationCards";

export interface HomeLocationCardsProps {
  className?: string;
}

export const HomeLocationCards = (props: HomeLocationCardsProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className}`}>
      <LocationCards limit={4} />
      <Container>
        <div className="notfound mt-16 flex justify-center flex-col items-center">
          <LocationSectionHeader>
            Nenašli ste hľadanú lokalitu?{" "}
          </LocationSectionHeader>
          <Link to={routes.realEstateLocations.to}>
            <Button>VŠETKY REALITNÉ LOKALITY</Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};
