import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes";
import { ArticleImageThumbnail } from "../../Blog/ArticleImageThumbnail/ArticleImageThumbnail";
import { useArticles } from "../../Blog/ArticleList/hooks/useArticles";
import { LocationSectionHeader } from "../../Location/shared/LocationSectionHeader/LocationSectionHeader";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";

export interface LatestArticlesProps {
  className?: string;
}

export const LatestArticles = (props: LatestArticlesProps) => {
  const { className = "" } = props;
  const articles = useArticles();

  return (
    <section className={`${className}`}>
      <Container>
        <SectionHeader
          className="text-center"
          title="AKTUÁLNE ČLÁNKY"
          subtitle="Realitný magazín"
        />
        <div className="items flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 mt-14">
          {articles.slice(0, 3).map((article) => (
            <ArticleImageThumbnail key={article.slug} {...article} />
          ))}
        </div>
        <div className="notfound mt-16 flex justify-center flex-col items-center">
          <LocationSectionHeader className="text-center">
            Viac hodnotných článkov nájdete v realitnom magazíne
          </LocationSectionHeader>
          <Link to={routes.blog.to}>
            <Button>OTVORIŤ REALITNÝ MAGAZÍN</Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};
