import { Box } from "@chakra-ui/react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";
import { Button } from "../../../Shared/Button/Button";

export interface HomeCooperationCardProps extends Home.CooperationCard {
  className?: string;
  href: string;
}

export const HomeCooperationCard = (props: HomeCooperationCardProps) => {
  const { className = "", image, titile, subtitle, button, href } = props;
  const firstImage = getImage(image.childImageSharp);
  const bgImage = convertToBgImage(firstImage);

  return (
    <Link to={href}>
      <Box
        shadow="dark-lg"
        _hover={{
          shadow: "2xl",
        }}
        as="article"
        className={`${className} group cursor-pointer transition-all duration-300 `}
      >
        <BackgroundImage preserveStackingContext {...bgImage}>
          <Box
            minH={{
              base: 280,
            }}
            className="relative flex flex-col justify-center"
          >
            <div className="overlay h-full w-full bg-gray-900 opacity-60 absolute z-0 group-hover:opacity-50 transition-all duration-300"></div>
            <div className="content z-10 relative flex flex-col items-center justify-center h-full p-6">
              <h2 className="text-primary text-4xl lg:text-5xl font-medium uppercase text-center">
                {titile}
              </h2>
              <h5 className="text-white font-medium text-lg lg:text-2xl mt-2 text-center">
                {subtitle}
              </h5>
              <Link
                className="mt-4 lg:opacity-0 group-hover:opacity-100 transition-all duration-500 "
                to={href}
              >
                <Button>{button}</Button>
              </Link>
            </div>
          </Box>
        </BackgroundImage>
      </Box>
    </Link>
  );
};
