import { gql } from "graphql-request";

export const CREATE_VALUATION_FORM_SUBMISSION_MUTATION = gql`
mutation submitForm(
  $email: String,
  $phone: String,
  $nameAndSurname: String,
  $typeAndLocation: String,
  $agreement: Boolean,
) {
  createFormSubmission(input: {data: {
    type: valuation,
    formData: [
      {
        __typename: "ComponentFormsValuationForm",
        nameAndSurname: $nameAndSurname,
        email: $email,
        phone: $phone,
        typeAndLocation: $typeAndLocation,
        agreement: $agreement
      }
    ]
  }}) {
    formSubmission {
      id
    }
  }
}
`