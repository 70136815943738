import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { Button } from "../../../Button/Button";
import { ServiceItem } from "../hooks/useServicePickerItems";

export interface ServicePickerItemProps extends ServiceItem {
  className?: string;
}

export const ServicePickerItem = (props: ServicePickerItemProps) => {
  const { className = "", image, title, text, link } = props;
  return (
    <article
      className={`${className} w-60 text-center flex flex-col justify-between`}
      {...props}
    >
      <div className="info">
        <div className="relative flex justify-center image rounded-full overflow-hidden shadow-lg w-40 mx-auto h-40">
          <GatsbyImage fluid={image} className="mx-auto w-full" />
        </div>
        <h6 className="title mt-4 text-xl font-medium">{title}</h6>
        <p className="mt-2">{text}</p>
      </div>
      <Link to={link.href} className="mt-6">
        <Button>{link.label}</Button>
      </Link>
    </article>
  );
};
