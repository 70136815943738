import React from "react";
import { Container } from "../../Container/Container";
import { ServicePickerItem } from "./components/ServicePickerItem";
import { useServicePickerItems } from "./hooks/useServicePickerItems";

export interface ServicePickerProps {
  className?: string;
}

export const ServicePicker = (props: ServicePickerProps) => {
  const { className = "" } = props;
  const items = useServicePickerItems();

  return (
    <Container
      className={`${className} items flex justify-evenly flex-col items-center space-y-16 lg:space-y-0 lg:flex-row lg:items-stretch `}
    >
      {items.map((item, index) => (
        <ServicePickerItem
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-delay={300 + index * 150}
          data-sal-easing="ease"
          {...item}
          key={item.text}
        />
      ))}
    </Container>
  );
};
