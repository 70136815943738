import { Box } from "@chakra-ui/layout";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { routes } from "../../../lib/routes";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import SvgLogo from "./assets/Logo";
import { useHeroImage } from "./hooks/useHeroImage";

export interface HeroProps {
  className?: string;
}

export const Hero = (props: HeroProps) => {
  const { className = "" } = props;
  const image = useHeroImage();
  return (
    <section className={`${className}`}>
      <Container className="flex flex-col lg:flex-row space-y-12 items-center lg:space-y-0 overflow-hidden">
        <div
          data-sal="slide-right"
          data-sal-delay={200}
          data-sal-easing="ease"
          data-sal-duration="800"
          className="info flex flex-1 flex-col lg:pb-12"
        >
          <div className="logo w-full sm:w-80">
            <SvgLogo />
          </div>
          <Box
            as="h2"
            lineHeight={{
              base: "normal",
            }}
            className="text-3xl lg:text-5xl mt-14 font-medium text-secondary "
          >
            POTREBUJTE PREDAŤ ALEBO KÚPIŤ NEHNUTEĽNOSŤ?
          </Box>
          <h4 className="text-terciary mt-5 text-xl font-medium">
            Bezpečne Vás prevediem procesom predaja, alebo kúpy nehnuteľnosti
          </h4>
          <div className="button flex mt-12">
            <Link to={routes.contact.to}>
              <Button>MÁM ZÁUJEM O KONZULTÁCIU</Button>
            </Link>
          </div>
        </div>
        <div className="image w-60 sm:w-96 flex pl-8">
          <GatsbyImage loading="eager" fluid={image} className="w-full" />
        </div>
      </Container>
    </section>
  );
};
