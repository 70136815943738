import React from "react";
import { AppointmentFormSection } from "../components/Home/AppointmentFormSection/AppointmentFormSection";
import { Hero } from "../components/Home/Hero/Hero";
import { HomeLocationCards } from "../components/Home/HomeLocationCards/HomeLocationCards";
import { HomeMobileBanners } from "../components/Home/HomeMobileBanners/HomeMobileBanners";
import { HomeServices } from "../components/Home/HomeServices/HomeServices";
import { HowIWork } from "../components/Home/HowIWork/HowIWork";
import { LatestArticles } from "../components/Home/LatestArticles/LatestArticles";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="mt-12">
      <Hero />
    </div>
    <div className="sections space-y-24 pb-16">
      <HomeServices />
      <HowIWork />
      <AppointmentFormSection />
      <HomeMobileBanners />
      <HomeLocationCards />
      <LatestArticles />
    </div>
  </Layout>
);

export default IndexPage;
