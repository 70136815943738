import { graphql, useStaticQuery } from "gatsby";

export const useHomeCooperation = (): Home.CooperationOptions => {
  const {
    strapiHomePage: { cooperationOptions },
  } = useStaticQuery(graphql`
    query useHomeCooperation {
      strapiHomePage {
        cooperationOptions {
          description
          subtitle
          title
          tipper {
            titile
            subtitle
            button
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          agent {
            titile
            subtitle
            button
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  return cooperationOptions;
};
