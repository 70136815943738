import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import { routes } from "../../../../../lib/routes";

export interface ServiceItem {
  image: FluidObject;
  title: string;
  text: string;
  link: {
    href: string;
    label: string;
  };
}

export const useServicePickerItems = (): ServiceItem[] => {
  const { selling, buying, valuation } = useStaticQuery(graphql`
    query useServicePickerItems {
      selling: file(relativePath: { eq: "shared/services/selling.jpg" }) {
        childImageSharp {
          fluid(quality: 20) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      buying: file(relativePath: { eq: "shared/services/buying.jpg" }) {
        childImageSharp {
          fluid(quality: 20) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      valuation: file(relativePath: { eq: "shared/services/valuation.jpg" }) {
        childImageSharp {
          fluid(quality: 20) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return [
    {
      title: "Predaj nehnuteľnosti",
      text: "Predajte svoju nehnuteľnosť za navyššiu cenu!",
      image: selling.childImageSharp.fluid,
      link: {
        href: `${routes.realEstateServices.to}#predaj`,
        label: "PREDAŤ NEHNUTEĽNOSŤ",
      },
    },
    {
      title: "Kúpa nehnuteľnosti",
      text: "Nemáte čas venovať sa hľadaniu tej správnej nehnuteľnosti?",
      image: buying.childImageSharp.fluid,
      link: {
        href: `${routes.realEstateServices.to}#kupa`,
        label: "KÚPIŤ NEHNUTEĽNOSŤ",
      },
    },
    {
      title: "Ocenenie nehnuteľnosti",
      text: "Potrebujete zistiť hodnotu vašej nehnuteľnosti a neviete ako?",
      image: valuation.childImageSharp.fluid,
      link: {
        href: `${routes.realEstateServices.to}#ocenenie`,
        label: "OCENIŤ NEHNUTEĽNOSŤ",
      },
    },
  ];
};
