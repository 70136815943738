import React from "react";
import { routes } from "../../../lib/routes";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { HomeCooperationCard } from "./components/HomeCooperationCard";
import { useHomeCooperation } from "./hooks/useHomeCooperation";

export interface HomeMobileBannersProps {
  className?: string;
}

export const HomeMobileBanners = (props: HomeMobileBannersProps) => {
  const { className = "" } = props;
  const { title, subtitle, description, tipper, agent } = useHomeCooperation();
  return (
    <section className={`${className}`}>
      <Container>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          description={description}
          className="text-center"
        />
        <div className="banners grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          {/* Tipper */}
          <HomeCooperationCard
            {...tipper}
            href={routes.iWantToBecomeATipper.to}
          />

          <HomeCooperationCard
            {...agent}
            href={routes.iWantToBecomeAnAgent.to}
          />
        </div>
      </Container>
    </section>
  );
};
